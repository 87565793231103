<template>
  <a-config-provider :locale="locale" v-if="state.isLogin">
    <a-layout style="min-height:100vh">
      <OsMenus />
      <a-layout>
        <a-layout-header style="background: #fff; padding: 0">
          <div class="logo">电力施工作业管控及防误登杆识别辅助系统</div>
          <a-dropdown class="company-info">
            <a class="ant-dropdown-link" @click.prevent>
              {{ state.userInfo?.companyName }}:{{ state.userInfo?.username }}
              <DownOutlined />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <a href="/img/作业管控系统操作指南.pdf" target="_blank">操作指南</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="modifyPwdModal">修改密码</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="logout">退出登录</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </a-layout-header>
        <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }">
          <div class="h1-title" v-if="route.name != '首页'">
            <router-link :to="state.returnPath" v-if="state.isReturn">
              <a-button class="return-button">
                返回
              </a-button>
            </router-link>
            {{ route.name }}
          </div>
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-config-provider>
  <!-- 延期 -->
  <a-modal title="修改密码" width="350px" cancelText="关闭" okText="确认" :centered="true" @ok="handlePwdSuccess"
    v-model:open="state.showPwdModal" :confirm-loading="state.confirmLoading">
    <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" ref="pwdFormRef" :model="pwdFormState">
      <a-form-item label="原密码" :rules="state.pwdRules.old_pwd" name="old_pwd">
        <a-input password v-model:value="pwdFormState.old_pwd"></a-input>
      </a-form-item>
      <a-form-item label="新密码" :rules="state.pwdRules.pwd" name="pwd">
        <a-input password v-model:value="pwdFormState.pwd"></a-input>
      </a-form-item>
      <a-form-item label="确认新密码" :rules="state.pwdRules.confirm_new_pwd" name="confirm_new_pwd">
        <a-input password v-model:value="pwdFormState.confirm_new_pwd"></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
  <router-view v-if="!state.isLogin" />
</template>

<script setup>
import httpService from '@/common/api';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import OsMenus from "@/components/osMenus.vue";
import { useRoute } from 'vue-router';
import { reactive, watch, ref } from 'vue';
import { DownOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
const locale = zhCN
const route = useRoute();
const pwdFormRef = ref();
const state = reactive({
  isLogin: true,
  isReturn: false,
  returnPath: "",
  showPwdModal: false,
  confirmLoading: false,
  userInfo: {},
  pwdRules: {
    old_pwd: [{ required: true, trigger: 'blur', message: '原密码不能为空！' }],
    pwd: [{ required: true, trigger: 'blur', message: '新密码不能为空！' }],
    confirm_new_pwd: [{ required: true, trigger: 'blur', message: '确认新密码不能为空！' }],
  }
})

const pwdFormState = reactive({
  old_pwd: "",
  pwd: "",
  confirm_new_pwd: "",
})

const downloadZhinan = () => {
  window.location.href = '/src/assets/zhinan.pdf';
}

const modifyPwdModal = () => {
  state.showPwdModal = true;
}

const logout = () => {
  localStorage.setItem('os_token', '');
  window.location.href = '#/login'
}

const handlePwdSuccess = () => {
  state.confirmLoading = true;

  httpService.modifyPassword(pwdFormState).then(res => {
    state.confirmLoading = false;
    state.showPwdModal = false;
  }).catch(err => {
    state.confirmLoading = false;
    message.error(err.message)
  })
}

watch(() => {
  state.userInfo = JSON.parse(localStorage.getItem('userInfo'));

  let path = route.path;
  if (path == '/login') {
    state.isLogin = false
  } else {
    state.isLogin = true
  }

  switch (path) {
    case '/project_detail':
    case '/project_finish':
    case '/project_task':
      state.isReturn = true;
      state.returnPath = '/project';
      break;
    default:
      state.isReturn = false;
  }
})
</script>
<style>
.h1-title {
  font-weight: 600;
  font-size: 24px;
  padding-bottom: 30px;
}

.logo {
  color: #000;
  font-size: 18px;
  position: absolute;
  left: 20px;
  font-weight: 600;
}

.scenes-search-col {
  padding-right: 20px;
  padding-left: 20px;
}

.search-btn button {
  margin: 0 5px;
}

.pagination-block {
  margin: 5px;
  text-align: right;
}

.return-button {
  margin-right: 10px;
}

.company-info {
  float: right;
  padding-right: 50px;
}
</style>