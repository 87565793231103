import { createRouter, createWebHashHistory } from "vue-router";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      redirect: '/home',
      name: '',
      children: [{
        path: '/home',
        name: '首页',
        component: () => import('../views/home.vue')
      }]
    },
    {
      path: '/login',
      name: '用户登录',
      component: () => import('../views/login.vue')
    },
    {
      path: '/company',
      name: '企业账号',
      component: () => import('../views/system/company.vue')
    },
    {
      path: '/substation',
      name: '变电站管理',
      component: () => import('../views/xianbian/substation.vue')
    },
    {
      path: '/line',
      name: '线路管理',
      component: () => import('../views/xianbian/line.vue')
    },
    {
      path: '/project',
      name: '项目清单',
      component: () => import('../views/projectManage/project.vue')
    },
    {
      path: '/project_detail',
      name: '作业前准备',
      component: () => import('../views/projectManage/projectDetail.vue')
    },
    {
      path: '/project_finish',
      name: '作业后小结',
      component: () => import('../views/projectManage/projectFinish.vue')
    },
    {
      path: '/project_task',
      name: '作业中管控',
      component: () => import('../views/projectManage/projectTask.vue')
    }
  ]
})

export default router