<template>
  <a-layout-sider theme="light">
    <div class="logo1"></div>
    <a-menu v-model:selectedKeys="state.selectedKeys" v-model:openKeys="state.openKeys" theme="light" mode="inline">
      <a-menu-item key="home">
        <router-link to="/home">首页</router-link>
      </a-menu-item>
      <a-sub-menu key="system" v-if="state.userInfo?.isSuper">
        <template #title>
          <span>
            <span>系统管理</span>
          </span>
        </template>
        <a-menu-item key="company">
          <router-link to="/company">企业账号</router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="xianbian">
        <template #title>
          <span>
            <span>线变管理</span>
          </span>
        </template>
        <a-menu-item key="substation">
          <router-link to="/substation">变电站管理</router-link>
        </a-menu-item>
        <a-menu-item key="line">
          <router-link to="/line">线路管理</router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="projectManage">
        <template #title>
          <span>
            <span>项目管理</span>
          </span>
        </template>
        <a-menu-item key="project">
          <router-link to="/project">项目清单</router-link>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
  </a-layout-sider>
</template>

<script setup>
import { reactive, watch } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const state = reactive({
  selectedKeys: ['home'],
  userInfo: {},
  openKeys: []
})

watch(() => {
  let paths = route.path.split('/');
  let tempSelect = [];
  tempSelect.push(paths[paths.length - 1]);
  state.selectedKeys = tempSelect;

  switch (tempSelect[0]) {
    case 'company':
      state.openKeys = ['system'];
      break;
    case 'substation':
    case 'line':
      state.openKeys = ['xianbian'];
      break;
    case 'project':
    case 'project_detail':
    case 'project_finish':
    case 'project_task':
      state.openKeys = ['projectManage'];
      break;
  }
  state.userInfo = JSON.parse(localStorage.getItem('userInfo'));
})

</script>

<style>
.logo1 {
  color: #000;
  font-size: 16px;
  text-align: center;
  padding: 30px 0;
}
</style>