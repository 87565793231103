import requestAxios from './request.js'

const api = {}

api.login = params => requestAxios.request({
  url: '/admin/login',
  method: 'post',
  params
})

api.companyInfo = params => requestAxios.request({
  url: '/company/info',
  method: 'get',
  params
})


api.modifyPassword = params => requestAxios.request({
  url: '/admin/modify-password',
  method: 'post',
  params
})

// ------------------------- 企业账号相关  start-------------------------
api.companyLists = params => requestAxios.request({
  url: '/company/index',
  method: 'get',
  params
})

api.createCompany = params => requestAxios.request({
  url: '/company/add',
  method: 'post',
  params
})

api.updateCompany = params => requestAxios.request({
  url: '/company/update',
  method: 'post',
  params
})

api.deleteCompany = params => requestAxios.request({
  url: '/company/delete',
  method: 'post',
  params
})

api.openOrCloseCompany = params => requestAxios.request({
  url: '/company/open-or-close',
  method: 'post',
  params
})
// ------------------------- 企业账号相关  end-------------------------

// ------------------------- 变电站相关  start-------------------------
api.convertingStationLists = params => requestAxios.request({
  url: '/converting-station/index',
  method: 'get',
  params
})

api.createConvertingStation = params => requestAxios.request({
  url: '/converting-station/add',
  method: 'post',
  params
})

api.updateConvertingStation = params => requestAxios.request({
  url: '/converting-station/update',
  method: 'post',
  params
})

api.deleteConvertingStation = params => requestAxios.request({
  url: '/converting-station/delete',
  method: 'post',
  params
})

api.convertingStationAll = params => requestAxios.request({
  url: '/converting-station/all',
  method: 'get',
  params
})

api.circuitAll = params => requestAxios.request({
  url: '/circuit/all',
  method: 'get',
  params
})
// ------------------------- 变电站相关  end-------------------------

// ------------------------- 变电站相关  start-------------------------
api.circuitLists = params => requestAxios.request({
  url: '/circuit/index',
  method: 'get',
  params
})

api.createCircuit = params => requestAxios.request({
  url: '/circuit/add',
  method: 'post',
  params
})

api.updateCircuit = params => requestAxios.request({
  url: '/circuit/update',
  method: 'post',
  params
})

api.deleteCircuit = params => requestAxios.request({
  url: '/circuit/delete',
  method: 'post',
  params
})
// ------------------------- 变电站相关  end-------------------------

// ------------------------- 项目管理相关  start-------------------------
api.projectLists = params => requestAxios.request({
  url: '/project/index',
  method: 'get',
  params
})

api.projectInfo = params => requestAxios.request({
  url: '/project/info',
  method: 'get',
  params
})

api.createProject = params => requestAxios.request({
  url: '/project/add',
  method: 'post',
  params
})

api.updateProject = params => requestAxios.request({
  url: '/project/update',
  method: 'post',
  params
})

api.deleteProject = params => requestAxios.request({
  url: '/project/delete',
  method: 'post',
  params
})

api.projectGroupJobLists = params => requestAxios.request({
  url: '/project/group-job-list',
  method: 'get',
  params
})

api.projectAddGroupJob = params => requestAxios.request({
  url: '/project/add-group-job',
  method: 'post',
  params
})

api.projectEditGroupJob = params => requestAxios.request({
  url: '/project/update-group-job',
  method: 'post',
  params
})

api.projectDeleteGroupJob = params => requestAxios.request({
  url: '/project/delete-group-job',
  method: 'post',
  params
})

api.projectAddUser = params => requestAxios.request({
  url: '/project/add-project-user',
  method: 'post',
  params
})

api.projectEditUser = params => requestAxios.request({
  url: '/project/update-project-user',
  method: 'post',
  params
})


api.projectDeleteUser = params => requestAxios.request({
  url: '/project/delete-project-user',
  method: 'post',
  params
})

api.projectFileUpload = params => requestAxios.request({
  url: '/project/file-upload',
  method: 'post',
  params
})

api.projectFileDelete = params => requestAxios.request({
  url: '/project/project-file-delete',
  method: 'post',
  params
})

api.projectSummarize = params => requestAxios.request({
  url: '/project/summarize',
  method: 'post',
  params
})

api.updateGroupJobSchedule = params => requestAxios.request({
  url: '/project/update-group-job-schedule',
  method: 'post',
  params
})


api.projectJobList = params => requestAxios.request({
  url: '/project-job/job-list',
  method: 'get',
  params
})

api.projectAddJob = params => requestAxios.request({
  url: '/project-job/add',
  method: 'post',
  params
})

api.projectEditJob = params => requestAxios.request({
  url: '/project-job/update',
  method: 'post',
  params
})

api.projectDeleteJob = params => requestAxios.request({
  url: '/project-job/delete',
  method: 'post',
  params
})

api.projectScheduled = params => requestAxios.request({
  url: '/project-job/scheduled',
  method: 'post',
  params
})

api.projectStatusChange = params => requestAxios.request({
  url: '/project-job/status-change',
  method: 'post',
  params
})

api.projectConfirm = params => requestAxios.request({
  url: '/project/confirm',
  method: 'post',
  params
})

api.projectActGroupJob = params => requestAxios.request({
  url: '/project/act-group-job',
  method: 'post',
  params
})
// ------------------------- 项目管理相关  end-------------------------
export default api